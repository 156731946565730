import { defaultI18nNs } from '@cocoonspace/shared/config/default-i-18-n-ns-config'
import { Search } from 'lucide-react'
import type { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { buttonVariants } from '~/components/ui/button'
import { HeaderIcon } from '~/components/ui/header-icon'

const Page = () => {
	const { t } = useTranslation()

	return (
		<div className='flex min-h-[60vh] flex-col items-center justify-center gap-4'>
			<HeaderIcon>
				<Search size={26} />
			</HeaderIcon>

			<p className='font-bold text-lg'>{t('common:404.title')}</p>

			<Link
				href='/paris'
				className={buttonVariants({ variant: 'outline' })}
			>
				{t('cwNavbar:findCocoon')}
			</Link>
		</div>
	)
}

export default Page

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale as string, [...defaultI18nNs])),
	},
})
